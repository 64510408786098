<template>
  <v-container>
    <v-card
      class="mb-12"
      height="100%"
      flat
    >
      <v-card-text>
        <p class="text-h5">Observaciones</p>
        <p>¿Hay algo que debamos saber sobre este tablero o subcircuitos? Quizás si corresponde a un tablero de relevancia dentro de tu operación,
          si opera 24/7 o únicamente en franjas horarias específicas, etc.
          Cualquier cosa que nos sirva para entender más sobre este punto, nos sirve!</p>
      </v-card-text>

      <v-form>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.observations"
              filled
              name="input-7-4"
              label="Comentarios"
              clearable
              prepend-icon="mdi-comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn small color="primary" style="margin: 15px" @click="saveObservations" :loading="loading">
            Guardar
            <v-icon small right>mdi-content-save</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
    <Footer :currentStep="4" @nextStep="handleNextStep($event)"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Footer from './footer.vue'

export default {
  name: 'ww-observations',
  components: { Footer },
  data() {
    return {
      loading: false,
      form: {
        observations: null,
      }
    }
  },
  mounted() {
    if (this.boardId) {
      const wizard = this.docs.find((item) => item._id === this.$route.params.wizardId)
      const board = wizard.boards.find((item) => item._id === this.boardId)
      if (board) this.form.observations = board.observations
    }
  },
  computed: {
    ...mapGetters({
      boardId: 'boards/boardId',
      docs: 'boards/docs',
    }),
  },
  methods: {
    ...mapActions({
      storeElectricBoard: 'boards/STORE_ELECTRIC_BOARD_DATA',
    }),
    handleNextStep(nextStep) {
      this.$emit('nextStep', { nextStep, form: this.form.observations })
    },
    async saveObservations() {
      this.loading = true
      try {
        await this.storeElectricBoard({
          form: this.form.observations,
          wizardId: this.$route.params.wizardId,
          type: 'observations',
          boardId: this.boardId,  
        })
      } catch {
        this.showAlert = true
      } finally {
        this.loading = false
      }
    }
  },
}
</script>