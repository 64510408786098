let baseUrl = null

switch (window.location.hostname) {
  case 'wizard.wenuwork.cl':
    baseUrl = 'https://ws.wenuwork.cl'
    break
  case 'localhost':
    baseUrl = 'http://localhost:4040'
    break
  default:
    break
}

export default baseUrl
