<template>
  <div class="app">
    <div class="container">
      <v-alert
        :value="showAlert"
        type="error"
        class="alert"
        transition="scale-transition"
      >{{ message }}</v-alert>
      <Header :isLarge="true"/>
      <h3>
        Bienvenido al levantamiento de información remota Wenu Work
      </h3>
      <v-container class="form-container" :style="{ width: $vuetify.breakpoint.smAndUp ? '50%' : '100%' }">
        <v-form ref="login" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  color="orange lighten-2"
                  dark
                  v-model="form.email"
                  label="Ingresa tu correo"
                  placeholder="ejemplo@correo.cl"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.password"
                  label="Contraseña"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="fieldRules"
                  :type="show ? 'text' : 'password'"
                  color="orange lighten-2"
                  dark
                  required
                  autocomplete
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <div class="btn-container">
                <v-btn text color="primary" large @click="register">Registrarse</v-btn>
              </div>
              <v-spacer></v-spacer>
              <div class="btn-container">
                <v-btn text color="warning" large @click="logIn" :loading="loading">Iniciar Sesión</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Header from './partials/header.vue'

export default {
  name: 'ww-login',
  components: { Header },
  data() {
    return {
      loading: false,
      showAlert: false,
      message: null,
      form: {
        email: null,
        password: null,
      },
      valid: null,
      show: false,
      fieldRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length <= 50) || 'Este campo debe de contener menos de 50 letras',
      ],
      emailRules: [
        v => !!v || 'Email es requerido',
        v => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
    }
  },
  methods: {
    ...mapActions({ login: 'auth/LOGIN' }),
    fillGeneralDataForm(event) {
      this.form.generalData = event.form
      this.currentPage = 'wizardForm'
    },
    async logIn() {
      if (this.$refs.login.validate()) {
        this.loading = true
        const credentials = { email: this.form.email, password: this.form.password }
        try {
          const authenticated = await this.login({ credentials })
          if (authenticated.ok) {
            this.$emit('loginAccepted')
          } else {
            this.message = authenticated.message
            this.showAlert = true
          }
        } catch (error) {
          this.message = error.message
        } finally {
          this.loading = false
        }
      }
    },
    register() {
      this.$router.push({ name: 'register' })
    }
  },
}
</script>

<style scoped>
.app {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.app::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and transparency here */
  z-index: 1;
}
.container {
  position: relative;
  z-index: 2;
}
img {
  max-width: 100%; /* Ensure the logo scales properly */
  height: auto;
}
h3 {
  color: white; /* Set the text color to white */
  text-align: center; /* Center the text */
  margin: 10px 43px;
}
.form-container {
  padding: 20px;
  border-radius: 8px;
  background: #101010c1;
  margin-top: 50px;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}
</style>
