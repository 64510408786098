<template>
<div class="wizard-container">
  <Header :isLarge="false"/>
  <v-stepper v-model="step" height="100%">
    <div class="wizard-header">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Tablero Eléctrico
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Fotografías Tablero
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3"  step="3">
          Sub Circuitos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 4" step="4">
          Comentarios
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="5">
          Resumen
        </v-stepper-step>
      </v-stepper-header>
    </div>

    <v-stepper-items>
      <v-stepper-content step="1">
        <ElectricBoard
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="2">
        <ElectricBoardPics
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="3">
        <SubCircuits
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="4">
        <Observations
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="5">
        <Resume
          :electricBoards="electricBoards"
          :wizardId="wizardId"
          :status="status"
          @addBoard="addBoard"
          @removeBoard="removeBoard($event)"
          @editBoard="editBoard($event)"
          @sendForm="status = $event"
        />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { customAlphabet } from 'nanoid/non-secure'
import ElectricBoard from './partials/electricBoard.vue'
import Header from './partials/header.vue'
import ElectricBoardPics from './partials/electricBoardPics.vue'
import SubCircuits from './partials/subCircuits.vue'
import Observations from './partials/observations.vue'
import Resume from './partials/resume.vue'

const nanoid = customAlphabet('1234567890abcdef', 24)

export default {
  name: 'ww-wizard',
  components: {
    ElectricBoard,
    Header,
    ElectricBoardPics,
    SubCircuits,
    Observations,
    Resume,
  },
  data() {
    return {
      step: null,
      form: {
        _id: nanoid(),
        show: false,
        electricBoard: {},
        electricBoardPics: [],
        subCircuits: [],
        observations: null,
      },
      electricBoards: [],
      clearForm: false,
      status: null,
      wizardId: null,
    }
  },
  async mounted() {
    const { toResume, wizardId, status } = this.$route.params
    this.wizardId = wizardId
    const docFound = this.docs.find((item) => item._id === wizardId)

    if (docFound) {
      this.electricBoards = docFound.boards.map((item) => ({ ...item, show: false }))
    }
    this.status = status
    this.step = this.currentStep || 1
    await this.updateBoardId(this.form._id)
    if (toResume) await this.updateStep(5)
  },
  computed: {
    ...mapGetters({
      docs: 'boards/docs',
      currentStep: 'boards/currentStep',
      currentBoardId: 'boards/boardId',
    }),
  },
  methods: {
    ...mapActions({
      updateStep: 'boards/UPDATE_STEP',
      updateBoardId: 'boards/UPDATE_BOARD_ID',
    }),
    async handleNextStep(event) {
      const { nextStep, form } = event
      switch (this.step) {
        case 1:
          this.form.electricBoard = form
          break
        case 2:
          this.form.electricBoardPics = form
          break
        case 3:
          this.form.subCircuits = form
          break
        case 4:
          this.form.observations = form
          this.electricBoards.push(this.form)
          break
        default:
          break
      }
      this.updateStep(nextStep)
    },
    addBoard() {
      this.form = {
        _id: nanoid(),
        show: false,
        electricBoard: {},
        electricBoardPics: [],
        subCircuits: [],
        observations: null,
      }
      this.updateStep(1)
      this.updateBoardId(this.form._id)
    },
    removeBoard(indexToRemove) {
      this.electricBoards = this.electricBoards.filter((_, index) => index !== indexToRemove)
    },
    editBoard(indexToEdit) {
      this.form = { ...this.electricBoards[indexToEdit] }
      this.removeBoard(indexToEdit)
      this.updateStep(1)
      this.updateBoardId(this.form._id)
    },
  },
  watch: {
    currentStep(value) {
      this.step = value
    }
  },
}
</script>
<style scoped>
.wizard-container {
  height: 100%;
}
.wizard-header {
  padding: 20px;
}
.wizard-content {
  height: 100% !important;
}
</style>