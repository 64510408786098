<template>
  <v-container>
    <v-alert
      v-model="showAlert"
      border="top"
      :type="alertType"
      dismissible
    >
      {{ message }}
    </v-alert>
    <p class="text-h5">Resumen</p>
    <p>¡Felicitaciones! Completaste el levantamiento del tablero eléctrico</p>
    <p>Acá podrás ver todos los tableros que has levantado.</p>
    <p>¿Te falta subir la información de alguno? Selecciona “Nuevo Tablero”.
      Si ya has terminado, presiona “Enviar” y nosotros haremos el resto del trabajo.
      Si te faltan tableros pero necesitas continuar más adelante, puedes acceder a este espacio más tarde.
      Tu trabajo ya hecho está guardado y no tienes que repetirlo nuevamente.</p>
    
    <v-row v-for="(item, indexItem) of boards" :key="indexItem">
      <v-col cols="12">
        <v-card
          height="100%"
        >
          <v-card-title>
            {{ item.electricBoard?.name }}
            <v-btn
              icon
              @click="toggleShow(indexItem)"
            >
              <v-icon>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="info"
              :disabled="disabledEdition"
              @click="editItem(indexItem)"
            >
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              :disabled="electricBoards.length === 1 || disabledEdition"
              @click="removeItem(indexItem)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-expand-transition>
              <div v-show="item.show">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="info">mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{item.electricBoard?.ubication}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="info">mdi-folder-image</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ getImagesContent(indexItem) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="info">mdi-subdirectory-arrow-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{item.subCircuits.length}} Sub Circuitos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn small color="warning" @click="$emit('addBoard')" :disabled="disabledEdition">
          Nuevo Tablero
          <v-icon small right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn small color="primary" class="float-end" @click="sendForm" :loading="loading" :disabled="disabledEdition">
          Enviar
          <v-icon small right>mdi-send</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Footer :currentStep="5"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Footer from './footer.vue'

export default {
  name: 'ww-resume',
  components: { Footer },
  data() {
    return {
      loading: false,
      boards: [],
      showAlert: false,
      message: null,
      alertType: 'success'
    }
  },
  mounted() {
    this.boards = this.electricBoards
  },
  computed: {
    ...mapGetters({
      docs: 'boards/docs',
    }),
    disabledEdition() {
      return this.status === 'Enviado'
    },
    wizard() {
      return this.docs.find((item) => item._id === this.$route.params.wizardId)
    },
    electricBoards() {
      return this.wizard.boards.map((item) => ({ ...item, show: false }))
    },
    status() {
      return this.wizard.status
    }
  },
  methods: {
    ...mapActions({
      sendFormToUpdate: 'boards/SEND_FORM'
    }),
    toggleShow(index) {
      this.electricBoards[index].show = !this.electricBoards[index].show
    },
    getImagesContent(index) {
      const images = this.electricBoards[index].electricBoardPics.filter((item) => item.url)
      return `${images.length} de ${this.electricBoards[index].electricBoardPics.length} imagenes`
    },
    removeItem(index) {
      this.$emit('removeBoard', index)
    },
    editItem(index) {
      this.$emit('editBoard', index)
    },
    async sendForm() {
      try {
        this.loading = true
        const sended = await this.sendFormToUpdate({ form: this.electricBoards, wizardId: this.$route.params.wizardId, status: 'Enviado' })
        if (sended) {
          this.$emit('sendForm', 'Enviado')
          this.message = 'Levantamiento enviado exitosamente'
          this.showAlert = true
        } else {
          this.message = 'Ocurrió un error al realizar el envio'
          this.alertType = 'error'
          this.showAlert = true
        }
      } catch (error) {
        this.message = 'Ocurrió un error al realizar el envio'
        this.alertType = 'error'
        this.showAlert = true
      }
      this.loading = false
    }
  },
}
</script>
<style scoped>
.container {
  height: 100%;
}
</style>