<template>
  <div>
    <div v-if="isLarge" :class="logoContainerClass">
      <v-img
        contain
        src="../../assets/images/wenuwork-transparent.png"
        :max-height="logoHeight"
      ></v-img>
    </div>
    <v-toolbar v-else flat height="65" color="primary" class="pt-2">
      <div :class="logoContainerClass">
        <v-img
          contain
          src="../../assets/images/wenuwork-transparent.png"
          :max-height="logoHeight"
        ></v-img>
      </div>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            class="mb-4"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <h3>{{ userName }}</h3>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch x-small v-model="isDarkTheme" @change="toggleTheme"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Modo Oscuro</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn text x-small color="primary" @click="goHome">
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn text x-small color="primary" @click="logout">
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ww-header',
  props: {
    isLarge: Boolean,
  },
  data() {
    return {
      isDarkTheme: this.$vuetify.theme.dark,
    }
  },
  created() {
    const savedThemePreference = localStorage.getItem('isDarkTheme')
    if (savedThemePreference !== null) {
      this.isDarkTheme = JSON.parse(savedThemePreference)
      this.$vuetify.theme.dark = this.isDarkTheme
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    logoContainerClass() {
      return {
        'logo-container': true,
        'large-logo': this.isLarge,
        'small-logo': !this.isLarge
      }
    },
    logoHeight() {
      return this.isLarge ? 75 : 30
    },
    userName() {
      return this.user.profile.firstName + ' ' + this.user.profile.lastName
    }
  },
  methods: {
    ...mapActions({
      requestLogout: 'auth/LOGOUT'
    }),
    toggleTheme() {
      this.$vuetify.theme.dark = this.isDarkTheme
      localStorage.setItem('isDarkTheme', this.isDarkTheme)
    },
    goHome() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    logout() {
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
      this.requestLogout()
    }
  },
}
</script>
<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.large-logo {
  height: 200px;
}

.small-logo {
  height: 50px;
  margin-bottom: 20px;
}
</style>
