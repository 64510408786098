// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import boards from './modules/boards'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    boards,
  },
  plugins: [
    createPersistedState({
      paths: [
        'app',
        'auth',
        'boards',
      ],
    }),
  ],
})

export default store
