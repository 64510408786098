<template>
  <v-container>
    <div class="text-center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-row justify="space-around" style="margin: 20px 0">
              <v-btn
                fab
                x-small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="requestCameraPermission">
                <v-icon>
                  mdi-camera
                </v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                color="error"
                :disabled="!saved"
                @click="removePicture">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-row>
          </template>
          <v-card class="card">
            <v-toolbar
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="closeDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  :disabled="!capturedPhoto"
                  @click="savePicture"
                >
                  Guardar
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-img v-if="capturedPhoto" :src="capturedPhoto" class="video-element"></v-img>
              <div v-if="isCameraActive">
                <video ref="video" autoplay playsinline class="video-element"></video>
                <canvas ref="canvas" class="d-none"></canvas>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-row
                justify="space-around"
              >
                <v-col cols="2">
                  <v-btn
                    fab
                    small
                    color="warning"
                    @click="retakePhoto"
                    :disabled="isCameraActive">
                    <v-icon>
                      mdi-camera-retake
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    fab
                    color="default"
                    @click="capturePhoto"
                    :disabled="!isCameraActive">
                    <v-icon>
                      mdi-camera-iris
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    fab
                    small
                    color="default"
                    :disabled="!isCameraActive"
                    @click="switchCamera">
                    <v-icon>
                      mdi-camera-flip
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </div>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      capturedPhoto: null,
      videoStream: null,
      isCameraActive: false,
      dialog: false,
      saved: false,
      usingFrontCamera: true,
    }
  },
  methods: {
    async requestCameraPermission() {
      try {
        const permission = await navigator.permissions.query({ name: 'camera' });
        if (permission.state === 'granted' || permission.state === 'prompt') {
          await this.initCamera();
        } else {
          alert('Camera access denied or not supported.');
        }
      } catch (error) {
        alert('Error requesting camera permissions. Please check your browser settings.');
      }
    },
    async initCamera() {
      try {
        this.isCameraActive = true;
        const videoConstraints = this.usingFrontCamera
          ? { facingMode: 'user' }
          : { facingMode: { exact: 'environment' } };

        const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints });
        this.videoStream = stream;
        this.$refs.video.srcObject = stream;
        await this.$refs.video.play(); // Ensure the video starts playing
      } catch (error) {
        this.isCameraActive = false; // Reset if there's an error
      }
    },
    switchCamera() {
      this.usingFrontCamera = !this.usingFrontCamera;
      this.stopCamera();
      this.initCamera();
    },
    capturePhoto() {
      const video = this.$refs.video
      const canvas = this.$refs.canvas
      const context = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      context.drawImage(video, 0, 0, canvas.width, canvas.height)

      this.capturedPhoto = canvas.toDataURL('image/png')
      this.stopCamera()
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
        this.videoStream = null;
        this.isCameraActive = false;
      }
    },
    retakePhoto() {
      this.capturedPhoto = null
      this.requestCameraPermission()
    },
    savePicture() {
      this.dialog = false
      const picture = this.capturedPhoto
      this.capturedPhoto = null
      this.saved = true
      this.$emit('savePicture', picture)
    },
    removePicture() {
      this.$emit('removePicture')
    },
    closeDialog() {
      this.dialog = false
      this.stopCamera()
    }
  },
  beforeDestroy() {
    this.stopCamera()
  },
}
</script>

<style scoped>
canvas {
  display: none
}

.card {
  background: #101010;
}

.video-element {
  margin-top: 100px;
  width: 100%;
  height: auto;
  max-height: 700px; /* Adjust as needed for the desired height */
  border-radius: 8px;
  object-fit: cover;
}
</style>
