<template>
  <v-container class="container">
    <Header :isLarge="true"/>
    <h1>Confirma tu email</h1>
    <p v-if="message">{{ message }}</p>
    <p v-else>Procesando tu confirmación...</p>
  </v-container>
</template>

<script>
import axios from 'axios'
import Header from '@/components/partials/header.vue'
import apiURL from '@/constants/api.js'

export default {
  name: 'ww-confirmation',
  components: { Header },
  data() {
    return {
      message: ''
    }
  },
  created() {
    this.confirmEmail()
  },
  methods: {
    async confirmEmail() {
      try {
        const token = this.$route.query.token
        const response = await axios.post(`${apiURL}/auth/confirm-email`, { token })

        if (response.data.success === true) {
          this.message = response.data.message
        }
      } catch (error) {
        this.message = 'Hubo un error al confirmar su correo electrónico. Por favor, inténtelo de nuevo más tarde.'
      }
    }
  }
}
</script>

<style scoped>
  .container {
    background-color: #014171;
    color: #fafafa;
    height: 100%;
    width: 100%;
  }
</style>
