<template>
  <div class="main-container">
    <router-view @loginAccepted="handleLoginAccepted" @fillGeneralData="fillGeneralDataForm($event)"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ww-home',
  data() {
    return {
      form: {
        generalData: {}
      },
      currentPage: 'home',
    }
  },
  computed: {
    ...mapGetters({ authenticated: 'auth/authenticated' }),
  },
  methods: {
    handleLoginAccepted() {
      this.$router.push({ name: 'home' })
    },
    fillGeneralDataForm(event) {
      this.form.generalData = event.form
      this.currentPage = 'wizardForm'
    },
  },
}
</script>
<style scoped>
.main-container {
  height: 100%;
}
</style>