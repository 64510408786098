<template>
<v-container>
  <v-row>
    <v-col cols="10">
      <p class="text-h5">Sub Circuitos</p>
      <p>Los sub circuitos son las protecciones específicas del tablero que estamos revisando que sea de tu interés medir.
        Nos interesa saber el detalle de cada una de ellas para saber con certeza cuál es la mejor forma de medirlas.</p>
    </v-col>
    <v-col cols="2">
      <v-btn text color="primary" class="float-end" @click="addSubCircuit">
        Agregar
        <v-icon
          right
          dark
        >
          mdi-plus
        </v-icon>
        
      </v-btn>
    </v-col>
  </v-row>
  <v-row v-for="(subCircuit, indexSubCircuit) of form" :key="subCircuit.id">
    <v-col cols="12">
      <v-card
        height="100%"
      >
        <v-card-title>
          Sub Circuito {{ subCircuit.name }}
          <v-btn
            icon
            @click="subCircuit.show = !subCircuit.show"
          >
            <v-icon>{{ subCircuit.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            :disabled="form.length === 1"
            @click="removeSubCircuit(subCircuit.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-expand-transition>
            <v-form ref="form" v-show="subCircuit.show">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="subCircuit.name"
                    label="Sub Circuito"
                    placeholder="Ej: Iluminación"
                    :rules="fieldRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="subCircuit.InProteccion"
                    label="In Protección General (A)"
                    placeholder="Ej. 3 x 120"
                    :rules="fieldRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <div style="margin-top: 20px">
                <p class="text-h6">
                  Fotografías Sub Circuitos
                </p>
                <div v-for="(item, indexPhoto) of subCircuit.photoList" :key="indexPhoto">
                  <strong>{{ item.name }}</strong> <v-btn x-small text @click="showReferenceImage(item.referenceImage)">(Ver imagen de referencia)</v-btn>
                  <p>{{ item.description }}</p>
                  <v-card class="mb-12 mt-10 ml-auto mr-auto" height="100%" :style="{ width: $vuetify.breakpoint.smAndUp ? '50%' : '100%' }">
                    <v-card-actions>
                      <v-btn text @click="item.capture = true" :disabled="!!item.imageFile">
                        Tomar Foto
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        :disabled="!!item.capturedPhoto"
                        @click="item.capture = false"
                      >
                        Subir Archivo
                      </v-btn>
                    </v-card-actions>
                    <v-container v-if="item.capture !== null">
                      <v-skeleton-loader
                        v-if="item.loading"
                        class="mx-auto"
                        max-width="300"
                        type="image"
                      ></v-skeleton-loader>
                      <v-img v-if="item.url" :src="item.url" class="video-element"></v-img>
                      <CapturePhoto
                        v-if="item.capture === true"
                        @savePicture="savePicture($event, indexSubCircuit, indexPhoto)"
                        @removePicture="removePicture(indexSubCircuit, indexPhoto)"
                      />
                      <v-file-input
                        v-model="item.imageFile"
                        v-if="item.capture === false"
                        accept="image/*"
                        label="Cargar imagen"
                        style="margin: 15px 0"
                        @change="handleChange($event, indexSubCircuit, indexPhoto)"
                      ></v-file-input>
                    </v-container>
                  </v-card>
                </div>
              </div>
            </v-form>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row justify="end">
    <v-btn small color="primary" style="margin: 15px" @click="saveSubCircuits" :loading="loading">
      Guardar
      <v-icon small right>mdi-content-save</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="dialog"
        class="mx-auto"
        elevation="5"
        max-width="374"
      >
        <v-img :src="referenceImage.url" class="reference-image"></v-img>
        <v-card-title>{{ referenceImage.title }}</v-card-title>
        <v-card-text>
          <p>{{ referenceImage.description }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <v-row align="end">
    <v-col cols="12" align-self="end">
      <Footer :currentStep="3" @nextStep="handleNextStep($event)"/>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import { nanoid } from 'nanoid'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

import Footer from './footer.vue'
import CapturePhoto from './capturePhoto.vue'
import imageRef from '@/constants/imageRef'
import apiURL from '@/constants/api.js'

export default {
  name: 'ww-electric-board',
  components: { CapturePhoto, Footer },
  data() {
    return {
      loading: false,
      dialog: false,
      form: [{
        id: nanoid(24),
        show: true,
        name: null,
        InProteccion: null,
        photoList: [
          {
            id: 1,
            name: 'Fotografía 1',
            description: 'In Protección',
            url: null,
            fileName: null,
            referenceImage: 'imageRef7',
            capturedPhoto: null,
            imageFile: null,
            capture: null,
            loading: false,
          },
          {
            id: 2,
            name: 'Fotografía 2',
            description: 'Cableado',
            url: null,
            fileName: null,
            referenceImage: 'imageRef8',
            capturedPhoto: null,
            imageFile: null,
            capture: null,
            loading: false,
          },
        ]
      }],
      fieldRules: [
        v => !!v || 'Este campo es requerido',
      ]
    }
  },
  mounted() {
    if (this.boardId) {
      const wizard = this.docs.find((item) => item._id === this.$route.params.wizardId)
      const board = wizard.boards.find((item) => item._id === this.boardId)
      if (board) this.form = board.subCircuits.map((item) => ({ ...item, show: false }))
    }
  },
  computed: {
    ...mapGetters({
      authorization: 'auth/headers',
      boardId: 'boards/boardId',
      docs: 'boards/docs',
    }),
    conductorItems() {
      const condutors = []
      for (let indexPhoto = 1; indexPhoto <= 10; indexPhoto++) {
        condutors.push(indexPhoto)
      }
      return condutors
    },
  },
  methods: {
    ...mapActions({
      storeElectricBoard: 'boards/STORE_ELECTRIC_BOARD_DATA'
    }),
    addSubCircuit() {
      this.form.forEach((item) => item.show = false)
      this.form.unshift({
        id: nanoid(24),
        show: true,
        name: null,
        InProteccion: null,
        photoList: [
        {
          id: 1,
          name: 'Fotografía 1',
          description: 'In Protección',
          url: null,
          fileName: null,
          referenceImage: null,
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 2,
          name: 'Fotografía 2',
          description: 'Cableado',
          url: null,
          fileName: null,
          referenceImage: null,
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
      ]
      })
    },
    removeSubCircuit(id) {
      this.form = this.form.filter((item) => item.id !== id)
    },
    handleNextStep(nextStep) {
      const allValidated = this.$refs.form.map((form) => form.validate())
      if (!allValidated.includes(false)) {
        this.$emit('nextStep', { nextStep, form: this.form })
      }
    },
    async uploadFile(imageFile) {
      if (!imageFile) return { url: null }

      const formData = new FormData()
      formData.append('file', imageFile)

      try {
        const response = await axios.post(`${apiURL}/wizard-forms/upload-picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...this.authorization,
          },
        })
        return response.data
      } catch (error) {
        return error
      }
    },
    async deleteFile(fileName) {
      if (!fileName) return null

      try {
        const response = await axios.post(`${apiURL}/wizard-forms/delete-picture`, { fileName }, {
          headers: {
            'Content-Type': 'application/json',
            ...this.authorization,
          },
        })
        return response.data
      } catch (error) {
        return error
      }
    },
    async savePicture(picture, indexSubCircuit, indexPhoto) {
      this.form[indexSubCircuit].photoList[indexPhoto].loading = true
      const image = this.dataURLToBlob(picture)
      const fileUpdated = await this.uploadFile(image)
      this.form[indexSubCircuit].photoList[indexPhoto].capturedPhoto = true
      this.form[indexSubCircuit].photoList[indexPhoto].url = fileUpdated.url
      this.form[indexSubCircuit].photoList[indexPhoto].fileName = fileUpdated.fileName
      this.form[indexSubCircuit].photoList[indexPhoto].loading = false
    },
    async removePicture(indexSubCircuit, indexPhoto) {
      this.form[indexSubCircuit].photoList[indexPhoto].capturedPhoto = false
      this.form[indexSubCircuit].photoList[indexPhoto].imageFile = null
      this.form[indexSubCircuit].photoList[indexPhoto].url = null

      await this.deleteFile(this.form[indexSubCircuit].photoList[indexPhoto].fileName)
    },
    async handleChange(file, indexSubCircuit, indexPhoto) {
      // this.form[indexSubCircuit].photoList[indexPhoto].capturedPhoto = file ? URL.createObjectURL(file) : null

      if (file) {
        this.form[indexSubCircuit].photoList[indexPhoto].loading = true
        this.form[indexSubCircuit].photoList[indexPhoto].capturedPhoto = false
        const fileUpdated = await this.uploadFile(file)
        this.form[indexSubCircuit].photoList[indexPhoto].url = fileUpdated.url
        this.form[indexSubCircuit].photoList[indexPhoto].fileName = fileUpdated.fileName
        this.form[indexSubCircuit].photoList[indexPhoto].loading = false
      } else {
        this.removePicture(indexSubCircuit, indexPhoto)
      }
    },
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1])
      const mimeString = dataURL.split(',')[0].split(':')[1].split('')[0]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    async saveSubCircuits() {
      this.loading = true
      try {
        const form = this.form.map((item) => {
          const photoList = item.photoList.map((image) => ({
            id: image.id,
            name: image.name,
            description: image.description,
            url: image.url,
            fileName: image.fileName,
          }))

          return {
            id: item.id,
            name: item.name,
            InProteccion: item.InProteccion,
            photoList,
          }
        })

        await this.storeElectricBoard({
          form,
          wizardId: this.$route.params?.wizardId ?? null,
          type: 'subCircuits',
          boardId: this.boardId,
        })
      } catch (error) {
        this.showAlert = true
      } finally {
        this.loading = false
      }
    },
    showReferenceImage(value) {
      this.referenceImage = imageRef[value]
      this.dialog = true
    },
  },
}
</script>
<style scoped>
.addButtons {
  margin-bottom: 20px;
}
.video-element {
  border-radius: 6px;
  margin: 20px 0;
}
</style>