export default {
  imageRef1: {
    title: 'Tablero a cuerpo completo con puerta cerrada',
    description: 'Esta fotografía permite identificar los nombres de las protecciones al interior del tablero. Como se puede apreciar cada protección cuenta con su respectiva nomenclatura',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-1.png',
  },
  imageRef2: {
    title: 'Tablero a cuerpo completo con puerta interior abierta',
    description: 'Este tipo de imagen permite identificar el cableado al interior del tablero, encontrar los espacios donde podemos colocar nuestros dispositivos y realizar una cubicación de materiales.',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-2.png',
  },
  imageRef3: {
    title: 'Protección Eléctrica',
    description: 'Con este tipo de fotografías podemos visualizar las características técnicas de la protección eléctrica, principalmente la corriente nominal de dicha protección',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-3.png',
  },
  imageRef4: {
    title: 'Cantidad de conductores por fase',
    description: 'Con estas fotografías podemos identificar cuántos conductores existen por fase y así determinar qué rango de pinza es la indicada.',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-4.png',
  },
  imageRef5: {
    title: 'Barra Neutro',
    description: 'Con este tipo de fotografías se identifica la barra neutro que cuenta el tablero.',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-5.png',
  },
  imageRef6: {
    title: 'Ubicación y/o entorno del tablero.',
    description: 'Esta fotografía permite apreciar la ubicación y el entorno del tablero eléctrico. Proporciona una vista clara de cómo se encuentra instalado el tablero en su entorno operativo',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-6.png',
  },
  imageRef7: {
    title: 'Protección Eléctrica',
    description: 'Con este tipo de fotografías podemos visualizar las características técnicas de la protección eléctrica, principalmente la corriente nominal de dicha protección',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-7.png',
  },
  imageRef8: {
    title: 'Cableado.',
    description: 'Este tipo de imagen permite identificar el cableado del sub-circuito.',
    url: 'https://wenuworkmedia.blob.core.windows.net/images/image-ref-8.png',
  },
}
