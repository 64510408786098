<template>
<v-container>
  <v-alert
    :value="showAlert"
    type="error"
    class="alert"
    transition="scale-transition"
  >Hubo un error al intentar guardar el dato</v-alert>
  <v-card
    class="mb-12"
    height="100%"
  >
    <v-card-text>
      <p class="text-h5">
        Tablero Eléctrico
      </p>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.name"
              label="Nombre Tablero"
              placeholder="Ej: Tablero General"
              :rules="fieldRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.ubication"
              label="Ubicación"
              placeholder="Ej: Sala principal"
              :rules="fieldRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.InProteccion"
              label="In Protección General (A)"
              placeholder="Ej. 3 x 120"
              :rules="fieldRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.conductors"
              :items="conductorItems"
              label="Conductores por Fase"
              :rules="fieldRules"
              required
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.feeder"
              :items="feederItems"
              label="Sección del Alimentador (mm2)"
              :rules="fieldRules"
              required
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox
              v-model="form.fuseHolder"
              label="Presencia de Portafusibles"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox
              v-model="form.wifi"
              label="Presencia de Redes Wifi"
              class="text--primary"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.wifi">
          <v-col>
            <v-select
              v-model="form.wifiIntensity"
              :items="intensityItems"
              label="Seccione la Intensidad de la Red Wifi"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="form.mobile"
              label="Presencia de Señal 3G/4G"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.mobile">
          <v-col>
            <v-select
              v-model="form.mobileIntensity"
              :items="intensityItems"
              label="Seccione la Intensidad de la señal"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="form.mobile">
          <v-col>
            <v-select
              v-model="form.mobileSupplier"
              :items="mobileSupplierItems"
              label="Proveedor 3G/4G"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn small color="primary" style="margin: 5px" @click="saveElectricBoard" :loading="loading">
            Guardar
            <v-icon small right>mdi-content-save</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <Footer :currentStep="1" @nextStep="handleNextStep($event)"/>
</v-container>
</template>
<script>
import { nanoid } from 'nanoid'
import { mapActions, mapGetters } from 'vuex'
import Footer from './footer.vue'

export default {
  name: 'ww-electric-board',
  components: { Footer },
  data() {
    return {
      loading: false,
      showAlert: false,
      form: {
        id: nanoid(24),
        name: null,
        ubication: null,
        InProteccion: null,
        conductors: null,
        feeder: null,
        fuseHolder: false,
        wifi: false,
        wifiIntensity: null,
        mobile: null,
        mobileIntensity: null,
        mobileSupplier: null,
      },
      intensityItems: ['Baja', 'Media', 'Alta'],
      feederItems: [
        '0 - 6',
        '7 - 16',
        '17 - 25',
        '26 - 50',
        '51 - 120',
        '121 - 185',
        '186 - 300',
        '301 - 500',
        '501 - 630',
      ],
      mobileSupplierItems: [
        'Claro',
        'Entel',
        'Movistar',
        'Wom',
      ],
      fieldRules: [
        v => !!v || 'Este campo es requerido',
      ]
    }
  },
  mounted() {
    if (this.boardId) {
      const wizard = this.docs.find((item) => item._id === this.$route.params.wizardId)
      const board = wizard.boards.find((item) => item._id === this.boardId)
      if (board) this.form = board.electricBoard
    }
  },
  computed: {
    ...mapGetters({
      boardId: 'boards/boardId',
      docs: 'boards/docs',
    }),
    conductorItems() {
      const condutors = []
      for (let index = 1; index <= 10; index++) {
        condutors.push(index)
      }
      return condutors
    },
  },
  methods: {
    ...mapActions({
      storeElectricBoard: 'boards/STORE_ELECTRIC_BOARD_DATA'
    }),
    handleNextStep(nextStep) {
      if (this.$refs.form.validate()) {
        this.$emit('nextStep', { nextStep, form: this.form })
      }
    },
    async saveElectricBoard() {
      this.loading = true
      try {
        await this.storeElectricBoard({
          form: this.form,
          wizardId: this.$route.params?.wizardId ?? null,
          type: 'electricBoard',
          boardId: this.boardId,  
        })
      } catch {
        this.showAlert = true
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
<style scoped>
</style>
