<template>
  <div class='app'>
    <div class="container">
      <Header :isLarge="true"/>
      <div class="form-container">
        <v-form ref="companyData" v-model="valid" v-if="currentStep === 'companyData'">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-subheader dark>Datos Compañía</v-subheader>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.company"
                  class="text-field"
                  label="Nombre compañía"
                  :rules="fieldRules"
                  color="orange lighten-2"
                  dark
                  clearable
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.address"
                  label="Dirección"
                  color="orange lighten-2"
                  dark
                  :rules="fieldRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-subheader dark>Contacto Mantenimiento</v-subheader>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12"  md="6">
                <v-text-field
                  v-model="form.maintenance"
                  label="Correo"
                  color="orange lighten-2"
                  dark
                  placeholder="ejemplo@correo.cl"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12"  md="6">
                <VuePhoneNumberInput
                  v-model="maintenancePhone.phone"
                  default-country-code="CL"
                  color="orange"
                  dark
                  dark-color="#101010"
                  valid-color="white"
                  :border-radius="8"
                  :translations="{
                    countrySelectorLabel: 'Codigo país',
                    phoneNumberLabel: 'Número de teléfono',
                  }"
                  clearable
                  required
                  @update="handlePhoneChange($event, 'maintenancePhone')"
                />
              </v-col>

              <v-col cols="12">
                <v-subheader dark>Contacto TI</v-subheader>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12"  md="6">
                <v-text-field
                  v-model="form.ITContact"
                  label="Correo"
                  color="orange lighten-2"
                  dark
                  placeholder="ejemplo@correo.cl"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12"  md="6">
                <VuePhoneNumberInput
                  v-model="ITContactPhone.phone"
                  default-country-code="CL"
                  color="orange"
                  dark
                  dark-color="#101010"
                  valid-color="white"
                  :border-radius="8"
                  :translations="{
                    countrySelectorLabel: 'Codigo país',
                    phoneNumberLabel: 'Número de teléfono',
                  }"
                  clearable
                  required
                  @update="handlePhoneChange($event, 'ITContactPhone')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="btn-container">
                  <v-btn elevation="2" color="warning" large  @click="handleNextStep('companyData', 'startAsistant')">Siguiente</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-form v-model="valid" v-if="currentStep === 'startAsistant'">
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <p class="text-center" style="color: #fff">¡Estamos listos para comenzar el levantamiento de información eléctrica!</p>
                <p class="text-center" style="color: #fff">A continuación, iremos paso a paso pidiéndote información específica de cada punto de medición que sea de interés. </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="btn-container">
                  <v-btn elevation="2" color="warning" large  @click="handleGeneralData" :loading="loading">COMENZAR</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Header from './partials/header.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ww-welcome',
  components: { Header, VuePhoneNumberInput },
  data() {
    return {
      valid: null,
      loading: false,
      currentStep: 'companyData',
      maintenancePhone: {
        phone: null,
        isValid: false
      },
      ITContactPhone: {
        phone: null,
        isValid: false
      },
      form: {
        company: null,
        address: null,
        maintenance: null,
        maintenancePhone: null,
        ITContact: null,
        ITContactPhone: null,
      },
      emailRules: [
        v => !!v || 'Email es requerido',
        v => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
      fieldRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length <= 50) || 'Este campo debe de contener menos de 50 letras',
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      storeGeneralData: 'boards/STORE_GENERAL_DATA'
    }),
    handlePhoneChange(event, type) {
      if (event.isValid) {
        this.form[type] = event.formattedNumber
        this[type].isValid = true
      } else {
        this[type].isValid = false
      }
    },
    handleNextStep(form, nextStep) {
      if (this.$refs[form].validate() && this.maintenancePhone.isValid && this.ITContactPhone.isValid) {
        this.currentStep = nextStep
        this.$refs[form].resetValidation()
      }
    },
    async handleGeneralData() {
      this.loading = true
      try {
        const form = { generalData: { ...this.form } }
        const userId = this.user.id
        const status = 'Activo'
        const wizardId = await this.storeGeneralData({ form, userId, status })

        if (wizardId) {
          this.$router.push({ name: 'wizard', params: { wizardId } })
        }
      } catch {
        return null
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
.app {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.app::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and transparency here */
  z-index: 1;
}

.container {
  position: relative;
  z-index: 2;
}
img {
  max-width: 100%; /* Ensure the logo scales properly */
  height: auto;
}
h3 {
  color: white; /* Set the text color to white */
  text-align: center; /* Center the text */
  margin: 10px 43px;
}
.form-container {
  padding: 20px;
  border-radius: 8px;
  background: #101010c1;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
</style>
