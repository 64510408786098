<template>
  <div class='app'>
    <div class="container">
      <v-alert
        :value="showAlert"
        type="info"
        class="alert"
        transition="scale-transition"
      >Se ha enviado un correo de confirmación, favor confirme su correo y luego inicie sesión</v-alert>
      <Header :isLarge="true"/>
      <div class="form-container">
        <h3>
          Registro de usuario
        </h3>
        <v-form ref="personalData" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.firstName"
                  label="Ingresa tu nombre"
                  color="orange lighten-2"
                  dark
                  :rules="fieldRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.lastName"
                  label="Ingresa tu apellido"
                  color="orange lighten-2"
                  dark
                  :rules="fieldRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12"  md="6">
                <v-text-field
                  v-model="form.email"
                  label="Ingresa tu correo de empresa"
                  color="orange lighten-2"
                  dark
                  placeholder="ejemplo@correo.cl"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12"  md="6">
                <VuePhoneNumberInput
                  v-model="contact.phone"
                  default-country-code="CL"
                  color="orange"
                  dark
                  dark-color="#101010"
                  valid-color="white"
                  :border-radius="8"
                  :translations="{
                    countrySelectorLabel: 'Codigo país',
                    phoneNumberLabel: 'Número de teléfono',
                  }"
                  clearable
                  required
                  @update="handlePhoneChange($event)"
                />
              </v-col>

              <!-- Password -->
              <v-col cols="12"  md="6">
                <v-text-field
                  v-model="form.password"
                  label="Ingresa una contraseña"
                  color="orange lighten-2"
                  dark
                  placeholder="ejemplo@correo.cl"
                  :rules="[rules.required, rules.minLength, rules.uppercase, rules.lowercase, rules.numeric]"
                  required
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  autocomplete
                ></v-text-field>
              </v-col>
              <v-col cols="12"  md="6">
                <v-text-field
                  v-model="form.passwordConfirmation"
                  label="Reingresa la contraseña"
                  color="orange lighten-2"
                  dark
                  placeholder="ejemplo@correo.cl"
                  :rules="[rules.required, rules.passwordMatch]"
                  required
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  autocomplete
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
                <div class="btn-container">
                  <v-btn text color="primary" large @click="goToLogin">Iniciar Sesión</v-btn>
                </div>
                <v-spacer></v-spacer>
                <div class="btn-container">
                  <v-btn text color="warning" large @click="registerUser" :disabled="disabled" :loading="loading">Registrarse</v-btn>
                </div>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script>
import Header from './partials/header.vue'
import { mapActions } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ww-register',
  components: { Header, VuePhoneNumberInput },
  data() {
    return {
      loading: false,
      show: false,
      showAlert: false,
      valid: null,
      currentStep: 'personalData',
      disabled: false,
      contact: {
        phone: null,
        isValid: false
      },
      form: {
        firstName: null,
        lastName: null,
        email: null,
        contact: null,
        password: null,
        passwordConfirmation: null,
      },
      emailRules: [
        v => !!v || 'Email es requerido',
        v => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
      fieldRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length <= 50) || 'Este campo debe de contener menos de 50 letras',
      ],
      rules: {
        required: value => !!value || 'Este campo es requiredo',
        passwordMatch: () => (this.form.password === this.form.passwordConfirmation) || 'Contraseñas no coinciden',
        minLength: value => (value && value.length >= 8) || 'Minimo 8 caracteres.',
        uppercase: value => /[A-Z]/.test(value) || 'Al menos 1 mayúscula.',
        lowercase: value => /[a-z]/.test(value) || 'Al menos 1 minuscula.',
        numeric: value => /[0-9]/.test(value) || 'Al menos 1 número.',
      }
    }
  },
  methods: {
    ...mapActions({ register: 'auth/REGISTER' }),
    handleNextStep(form, nextStep) {
      if (this.$refs[form].validate() && this.contact.isValid) {
        if (form === 'companyData') {
          this.register(this.form)
        }
        this.currentStep = nextStep
        this.$refs[form].resetValidation()
      }
    },
    handlePhoneChange(event) {
      if (event.isValid) {
        this.form.contact = event.formattedNumber
        this.contact.isValid = true
      } else {
        this.contact.isValid = false
      }
    },
    handleGeneralData() {
      this.$emit('fillGeneralData', { form: this.form })
    },
    async registerUser() {
      this.loading = true
      const result = await this.register(this.form)
      if (result) {
        this.showAlert = true
        this.disabled = true
      }
      this.loading = false
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
<style scoped>
.app {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.app::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and transparency here */
  z-index: 1;
}
.container {
  position: relative;
  z-index: 2;
}
img {
  max-width: 100%; /* Ensure the logo scales properly */
  height: auto;
}
h3 {
  color: white; /* Set the text color to white */
  text-align: center; /* Center the text */
  margin: 10px 43px;
}
.form-container {
  padding: 20px;
  border-radius: 8px;
  background: #101010c1;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
