<template>
  <v-container>
    <v-card
      class="mb-12"
      height="100%"
    >
      <v-card-text>
        <p class="text-h5">
          Fotografías del Tablero Eléctrico
        </p>
        <p>Las fotografías de los tableros eléctricos son un elemento esencial para aterrizar toda la información que se obtuvo y se agregó en los pasos anteriores</p>
        <v-form>
          <div v-for="(item, index) of photoList" :key="index">
            <strong>{{ item.name }}</strong> <v-btn x-small text @click="showReferenceImage(item.referenceImage)">(Ver imagen de referencia)</v-btn>
            <p>{{ item.description }}</p>
            <v-card class="mb-12 mt-10 ml-auto mr-auto" height="100%" :style="{ width: $vuetify.breakpoint.smAndUp ? '50%' : '100%' }">
              <v-card-actions>
                <v-btn text @click="item.capture = true" :disabled="!!item.imageFile">
                  Tomar Foto
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  :disabled="!!item.capturedPhoto"
                  @click="item.capture = false"
                >
                  Subir Archivo
                </v-btn>
              </v-card-actions>
              <v-container v-if="item.capture !== null">
                <v-skeleton-loader
                  v-if="item.loading"
                  class="mx-auto"
                  max-width="300"
                  type="image"
                ></v-skeleton-loader>
                <v-img v-if="item.url && !item.loading" :src="item.url" class="video-element"></v-img>
                <CapturePhoto
                  v-if="item.capture === true"
                  @savePicture="savePicture($event, index)"
                  @removePicture="removePicture(index)"
                />
                <v-file-input
                  v-model="item.imageFile"
                  v-if="item.capture === false"
                  accept="image/*"
                  label="Cargar imagen"
                  style="margin: 15px 0"
                  @change="handleChange($event, index)"
                  required
                ></v-file-input>
              </v-container>
            </v-card>
          </div>
          <v-row justify="end">
            <v-btn small color="primary" style="margin: 5px" @click="saveElectricBoardPics" :loading="loading">
              Guardar
              <v-icon small right>mdi-content-save</v-icon>
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="dialog"
        class="mx-auto"
        elevation="5"
        max-width="374"
      >
        <v-img :src="referenceImage.url" class="reference-image"></v-img>
        <v-card-title>{{ referenceImage.title }}</v-card-title>
        <v-card-text>
          <p>{{ referenceImage.description }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Footer :currentStep="2" @nextStep="handleNextStep($event)"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import CapturePhoto from './capturePhoto.vue'
import Footer from './footer.vue'
import imageRef from '@/constants/imageRef'
import apiURL from '@/constants/api.js'

export default {
  name: 'ww-electric-board-pics',
  components: { Footer, CapturePhoto },
  data() {
    return {
      loading: false,
      capture: null,
      capturedFirstPhoto: null,
      selectedFile: null,
      dialog: false,
      referenceImage: null,
      photoList: [
        {
          id: 1,
          name: 'Fotografía 1',
          description: 'Tablero a cuerpo completo con puerta cerrada',
          url: null,
          fileName: null,
          referenceImage: 'imageRef1',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 2,
          name: 'Fotografía 2',
          description: 'Tablero a cuerpo completo con puerta interior abierta',
          url: null,
          fileName: null,
          referenceImage: 'imageRef2',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 3,
          name: 'Fotografía 3',
          description: 'Protección Eléctrica',
          url: null,
          fileName: null,
          referenceImage: 'imageRef3',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 4,
          name: 'Fotografía 4',
          description: 'Cantidad de conductores por fase',
          url: null,
          fileName: null,
          referenceImage: 'imageRef4',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 5,
          name: 'Fotografía 5',
          description: 'Barra Neutro',
          url: null,
          fileName: null,
          referenceImage: 'imageRef5',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        },
        {
          id: 6,
          name: 'Fotografía 6',
          description: 'Ubicación y/o entorno del tablero',
          url: null,
          fileName: null,
          referenceImage: 'imageRef6',
          capturedPhoto: null,
          imageFile: null,
          capture: null,
          loading: false,
        }
      ],
    }
  },
  mounted() {
    if (this.boardId) {
      const wizard = this.docs.find((item) => item._id === this.$route.params.wizardId)
      const board = wizard.boards.find((item) => item._id === this.boardId)

      if (board && board.electricBoardPics.length > 0) {
        this.photoList = board.electricBoardPics.map((image, index) => ({
          ...image,
          referenceImage: `imageRef${index + 1}`,
          ...(image.url ? { capture: true } : {}),
        }))
      }
    }
  },
  computed: {
    ...mapGetters({
      authorization: 'auth/headers',
      boardId: 'boards/boardId',
      docs: 'boards/docs',
    }),
  },
  methods: {
    ...mapActions({
      storeElectricBoard: 'boards/STORE_ELECTRIC_BOARD_DATA'
    }),
    handleNextStep(nextStep) {
      this.$emit('nextStep', { nextStep, form: this.photoList })
    },
    async savePicture(picture, index) {
      this.photoList[index].loading = true
      const image = this.dataURLToBlob(picture)
      const fileUpdated = await this.uploadFile(image)
      this.photoList[index].capturedPhoto = true
      this.photoList[index].url = fileUpdated.url
      this.photoList[index].fileName = fileUpdated.fileName
      this.photoList[index].loading = false
    },
    async removePicture(index) {
      this.photoList[index].imageFile = null
      await this.deleteFile(this.photoList[index].fileName)
      this.photoList[index].url = null
      this.photoList[index].capturedPhoto = false
    },
    async handleChange(file, index) {
      if (file) {
        this.photoList[index].loading = true
        this.photoList[index].capturedPhoto = false
        const fileUpdated = await this.uploadFile(file)
        this.photoList[index].url = fileUpdated.url
        this.photoList[index].fileName = fileUpdated.fileName
        this.photoList[index].loading = false
      } else {
        this.removePicture(index)
      }
    },
    async uploadFile(imageFile) {
      if (!imageFile) return { url: null }

      const formData = new FormData()
      formData.append('file', imageFile)

      try {
        const response = await axios.post(`${apiURL}/wizard-forms/upload-picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...this.authorization,
          },
        })
        return response.data
      } catch (error) {
        return error
      }
    },
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1])
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    async deleteFile(fileName) {
      if (!fileName) return null

      try {
        const response = await axios.post(`${apiURL}/wizard-forms/delete-picture`, { fileName }, {
          headers: {
            'Content-Type': 'application/json',
            ...this.authorization,
          },
        })
        return response.data
      } catch (error) {
        return error
      }
    },
    async saveElectricBoardPics() {
      this.loading = true
      try {
        const form = this.photoList.map((image) => {
          const imageData = {
            id: image.id,
            name: image.name,
            description: image.description,
            url: image.url,
            fileName: image.fileName,
          }
          return imageData
        })
        await this.storeElectricBoard({
          form,
          wizardId: this.$route.params?.wizardId ?? null,
          type: 'electricBoardPics',
          boardId: this.boardId,
        })
      } catch {
        this.showAlert = true
      } finally {
        this.loading = false
      }
    },
    showReferenceImage(value) {
      this.referenceImage = imageRef[value]
      this.dialog = true
    },
  },
}
</script>
<style scoped>
.video-element {
  border-radius: 6px;
  margin: 20px 0;
}
</style>