// router/index.js
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store' // Import the store

import Login from '../components/Login.vue'
import Main from '../views/home.vue'
import Welcome from '../components/Welcome.vue'
import Wizard from '../components/Wizard.vue'
import Register from '../components/Register.vue'
import Confirmation from '../views/confirmation.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/',
      name: 'home',
      component: Main
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome
    },
    {
      path: '/wizard/:wizardId',
      name: 'wizard',
      component: Wizard
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: Confirmation
    },
  ]
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/authenticated']

  const freeAccess = ['login', 'register', 'confirmation']
  if (!freeAccess.includes(to.name) && !isAuthenticated) {
    // Redirect to login if not authenticated
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
